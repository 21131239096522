import React from "react";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import request from "../../../requests/request";
import FlujoIngresoEgreso from "./FlujoIngresoEgreso";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  textoDePeriodoSeleccionado,
  TituloCard,
} from "../utils";
import Spinner from "../../../components/Spinner";

export default function IngresosEgresos({
  valueFecha,
  ingresosTotales,
  loadingIngresos,
}) {
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [ingreso, setIngreso] = React.useState(0);
  const [egreso, setEgreso] = React.useState(0);

  const handleGetIngresoEgreso = async () => {
    setIsLoading(true);

    try {
      const response = await request({
        url: "/api/venta/grafico/ingreso-egreso/",
        method: "GET",
        params: {
          desde: FECHA_DESDE(valueFecha),
          hasta: FECHA_HASTA(valueFecha),
        },
      });
      response.status === 200 ? setEgreso(response.data) : setEgreso(0);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  React.useEffect(() => {
    handleGetIngresoEgreso();
  }, [valueFecha]);

  React.useEffect(() => {
    let total = Array.isArray(ingresosTotales)
      ? ingresosTotales.reduce((acc, t) => t.total + acc, 0)
      : 0;
    setIngreso(total);
  }, [ingresosTotales]);

  React.useEffect(() => {
    setData({
      ingreso: ingreso,
      egreso: egreso,
      total: ingreso - egreso,
    });
    setIsLoading(false);
  }, [ingreso, egreso]);

  return (
    <div>
      <Card>
        <CardHeader
          title={<TituloCard titulo="Ingresos y Egresos" />}
          subheader={textoDePeriodoSeleccionado(valueFecha)}
        />
        <Divider />
        <CardContent>
          {!isLoading && !loadingIngresos ? (
            <FlujoIngresoEgreso dataIngresoEgreso={data} />
          ) : (
            <Spinner color="#66b3ff" type="spin" />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
