import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "shards-react";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import { ModulosContextProvider } from "../context/ModulosContext";
import { invalidToken, showModal } from "../Redux/Actions";
import { getConfigGeneral } from "../Redux/Actions/actionConfigGeneral";

export default function DefaultLayout({ children, noNavbar, location }) {
  const dispatch = useDispatch();

  const { configGeneral } = useSelector((state) => state.configGeneral);

  const showModalState = useSelector((state) => state.loginReducer.showModal);
  const modalRef = useRef(null);

  const validarToken = async () => {
    const token = localStorage.getItem("token");
    axios.defaults.headers = { "Content-Type": "application/json" };

    try {
      const { data } = await axios.get(`/api/usuario/validateToken/${token}`);
      if (!data) {
        localStorage.clear();
        dispatch(showModal(true));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    validarToken();
    dispatch(getConfigGeneral());
  }, []);

  const aceptarValidacion = () => {
    dispatch(invalidToken());
    dispatch(showModal(false));
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      aceptarValidacion();
    }
  };

  useEffect(() => {
    if (showModalState) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModalState]);

  return (
    <ModulosContextProvider>
      <div>
        <Modal
          open={showModalState}
          toggle={() => dispatch(showModal(!showModalState))}
        >
          <div ref={modalRef}>
            <ModalHeader>
              <h2>¡Atención!</h2>
            </ModalHeader>
            <ModalBody>
              <p className="text-center">
                Su cuenta fue iniciada en otro dispositivo.
              </p>
              <p className="text-center">
                Para continuar por favor presione Aceptar para volver a iniciar
                sesión.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button theme="success" onClick={aceptarValidacion}>
                Aceptar
              </Button>
            </ModalFooter>
          </div>
        </Modal>

        <Container
          fluid
          className={
            configGeneral.sidebar_menu_config
              ? "icon-sidebar-nav"
              : "icon-sidebar-nav-fixed"
          }
        >
          <Row>
            <MainSidebar hideLogoText />
            <Col className="main-content col" tag="main">
              {!noNavbar && <MainNavbar location={location} />}
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </ModulosContextProvider>
  );
}

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
};
